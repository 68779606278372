import React, { useState } from "react";
import { auth } from "../components/Firebase";
import { useHistory, Link } from "react-router-dom";

export default function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	const history = useHistory();

	const loginHandle = () => {
		auth
			.signInWithEmailAndPassword(email, password)
			.then(() =>
				history.push({
					pathname: `/`,
				})
			)
			.catch((error) => setError({ message: "Incorrect email/password" }));
	};

	return (
		<div className="rootContainer">
			<div className="pageContentLandingPage">
				<div className="mainContent">
					<div className="contentWrapper">
						<div className="content">
							<div className="signupContainer">
								<form className="signupForm" onSubmit={loginHandle}>
									<div className="loginTextContainer">Welcome back to EncryptedWallet</div>
									<div className="signupAltTextContainer">
										New here?{" "}
										<Link
											className="signupLogin"
											to={{
												pathname: "/register",
											}}
										>
											Create an account.
										</Link>
									</div>
									<div className="signupCustomInputContainer">
										<div className="signupInputLabelContainer">
											<div className="signupInputLabel">Email</div>
										</div>
										<div className="signupInputLabelContainerTwo">
											<input placeholder="Email" type="email" tabIndex="1000" value={email} onChange={(event) => setEmail(event.target.value)} />
										</div>
									</div>
									<div className="signupCustomInputContainer">
										<div className="signupInputLabelContainer">
											<div className="signupInputLabel">Password</div>
											<Link
												to={{
													pathname: "/recover-account",
												}}
											>
												<div className="loginRightLabel">Forgot Password?</div>
											</Link>
										</div>
										<div className="signupInputLabelContainerTwo">
											<input placeholder="Password" type="password" tabIndex="1000" value={password} onChange={(event) => setPassword(event.target.value)} />
										</div>
									</div>
									{error ? <div className="signupError">{error.message}</div> : ""}
									<div className="signupSubmit loginSubmit" onClick={loginHandle}>
										<div className="signupButtonLabel">Login</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../components/Firebase";

export default function Register() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	const registerHandle = () => {
		auth.createUserWithEmailAndPassword(email, password).catch((error) => setError(error));
	};

	return (
		<div className="rootContainer">
			<div className="pageContentLandingPage">
				<div className="mainContent">
					<div className="contentWrapper">
						<div className="content">
							<div className="signupContainer">
								<form className="signupForm" onSubmit={registerHandle}>
									<div className="loginTextContainer">Welcome to EncryptedWallet</div>
									<div className="signupAltTextContainer">
										Already have an account?{" "}
										<Link
											className="signupLogin"
											to={{
												pathname: "/login",
											}}
										>
											Log In.
										</Link>
									</div>
									{error ? <div className="signupError">{error.message}</div> : ""}
									<div className="signupCustomInputContainer">
										<div className="signupInputLabelContainer">
											<div className="signupInputLabel">Email</div>
										</div>
										<div className="signupInputLabelContainerTwo">
											<input placeholder="Email" type="email" tabIndex="1000" value={email} onChange={(event) => setEmail(event.target.value)} />
										</div>
									</div>
									<div className="signupCustomInputContainer">
										<div className="signupInputLabelContainer">
											<div className="signupInputLabel">Password</div>
											<Link
												to={{
													pathname: "/recover-account",
												}}
											></Link>
										</div>
										<div className="signupInputLabelContainerTwo">
											<input placeholder="Password" type="password" tabIndex="1000" value={password} onChange={(event) => setPassword(event.target.value)} />
										</div>
									</div>
									<div className="signupTerms">
										By clicking on Create Account, you agree to our{" "}
										<Link
											className="signupForgot"
											to={{
												pathname: "/terms-of-use",
											}}
										>
											Terms of Use
										</Link>
										.<br />
										<br />
										To learn more about how LeagueTracker collects, uses, shares and protects your personal data please read our{" "}
										<Link
											className="signupForgot"
											to={{
												pathname: "/privacy-policy",
											}}
										>
											Privacy Policy
										</Link>
										.
									</div>

									<div className="signupSubmit" onClick={registerHandle}>
										<div className="signupButtonLabel">Register</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

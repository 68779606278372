import React, { useEffect, useState } from "react";
import { auth } from "../components/Firebase";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import axios from "axios";

const CryptoJS = require("crypto-js");
const api = "https://bowls2go.com/api";
const testAPI = "http://localhost:5001/encryptedwallet-e3b7f/us-central1/api/api";

export default function CardList() {
	const [cardListEncrypted, setCardListEncrypted] = useState();
	const [cardList, setCardList] = useState([]);
	const [error, setError] = useState();
	const [refresh, setRefresh] = useState();
	const deleteCard = (card) => {
		axios
			.post(`${api}/delete-encrypted-card`, {
				email: auth.currentUser.email,
				cardNumber: cardListEncrypted[card].cardNumber,
			})
			.then((response) => {
				console.log(response);
				setRefresh(true);
			});
	};
	useEffect(() => {
		if (refresh) {
			setCardList([]);
			axios
				.post(`${api}/retrieve-encrypted-cards`, {
					email: auth.currentUser.email,
				})
				.then((response) => {
					if (!response.data.userData) setError("Error");
					else setCardListEncrypted(response.data.userData);
					setRefresh(false);
				});
		}
	}, [refresh]);

	useEffect(() => {
		axios
			.post(`${api}/retrieve-encrypted-cards`, {
				email: auth.currentUser.email,
			})
			.then((response) => {
				if (!response.data.userData) setError("Error");
				else setCardListEncrypted(response.data.userData);
			});
	}, []);

	useEffect(() => {
		if (cardListEncrypted)
			for (let i = 0; i < cardListEncrypted.length; i++) {
				const ciphertextdecryptedBytesCardNumber = CryptoJS.AES.decrypt(cardListEncrypted[i].cardNumber, auth.currentUser.uid);
				const ciphertextdecryptedBytesCVV = CryptoJS.AES.decrypt(cardListEncrypted[i].cvv, auth.currentUser.uid);
				const ciphertextdecryptedBytesMonth = CryptoJS.AES.decrypt(cardListEncrypted[i].month, auth.currentUser.uid);
				const ciphertextdecryptedBytesYear = CryptoJS.AES.decrypt(cardListEncrypted[i].year, auth.currentUser.uid);

				const cardNumber = JSON.parse(ciphertextdecryptedBytesCardNumber.toString(CryptoJS.enc.Utf8));
				const cvv = JSON.parse(ciphertextdecryptedBytesCVV.toString(CryptoJS.enc.Utf8));
				const month = JSON.parse(ciphertextdecryptedBytesMonth.toString(CryptoJS.enc.Utf8));
				const year = JSON.parse(ciphertextdecryptedBytesYear.toString(CryptoJS.enc.Utf8));

				setCardList((cardList) => [...cardList, { cardNumber, cvv, month, year }]);
			}
	}, [cardListEncrypted]);

	return (
		<div className="rootContainer">
			<Header page="cardList" />
			<div className="pageContentLandingPage">
				<div className="mainContent">
					<div className="contentWrapper">
						<div className="content">
							<div className="signupContainer">
								{error ? (
									error
								) : cardList.length === 0 ? (
									<LoadingIcon />
								) : (
									<form className="cardListForm">
										<div className="loginTextContainer">Card List</div>
										{cardList.map((card, index) => {
											return (
												<div key={index}>
													<div className="cardListAltContainer">Card Number {index + 1}</div>
													<div className="cardListCustomInputContainer">
														<div className="signupInputLabelContainer">
															<div className="signupInputLabel">Number</div>
														</div>
														<div className="signupInputLabelContainerTwo">{card.cardNumber}</div>
													</div>
													<div className="cardListCustomInputContainer">
														<div className="signupInputLabelContainer">
															<div className="signupInputLabel">CVV</div>
														</div>
														<div className="signupInputLabelContainerTwo">{card.cvv}</div>
													</div>
													<div className="cardListCustomInputContainer">
														<div className="signupInputLabelContainer">
															<div className="signupInputLabel">Expiration Month</div>
														</div>
														<div className="signupInputLabelContainerTwo">{card.month}</div>
													</div>
													<div className="cardListCustomInputContainer">
														<div className="signupInputLabelContainer">
															<div className="signupInputLabel">Expiration Year</div>
														</div>
														<div className="signupInputLabelContainerTwo">{card.year}</div>
													</div>
													<div className="signupSubmit loginSubmit" onClick={() => deleteCard(index)}>
														<div className="signupButtonLabel">Delete Card Number {index + 1}</div>
													</div>
													{error ? <div className="signupError">{error.message}</div> : ""}
												</div>
											);
										})}
									</form>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function LoadingIcon() {
	return (
		<div className="loaderContainer">
			<div className="loaderIcon isLoading"></div>
		</div>
	);
}

import React, { useState } from "react";
import { auth } from "../components/Firebase";
import { useHistory, Link } from "react-router-dom";

export default function RecoverAccount({ loggedIn }) {
	const [email, setEmail] = useState("");
	const [error, setError] = useState(false);
	const [complete, setComplete] = useState(false);

	const history = useHistory();

	const loginSubmit = (event) => {
		event.preventDefault();
		auth
			.sendPasswordResetEmail(email)
			.then((user) => {
				setError(false);
				setComplete(true);
			})
			.catch((error) => {
				if (error.code === "auth/user-not-found") setError({ message: "No account exists with that email address. Please try again." });
				else if (error.code === "auth/invalid-email") setError({ message: "A valid email address is required." });
				else setError(error);
			});
	};

	return (
		<div className="rootContainer">
			<div className="pageContentLandingPage">
				<div className="mainContent">
					<div className="contentWrapper">
						<div className="content">
							<div className="signupContainer">
								<form className="signupForm" onSubmit={loginSubmit}>
									{!complete ? (
										<div>
											<div className="loginTextContainer">Recovering your EncryptedWallet account</div>
											<div className="recoverAltTextContainer">
												Enter the email address associated with your account and we’ll send you a link to reset your password.
											</div>
											{error ? <div className="recoverError">{error.message}</div> : ""}
											<div className="signupCustomInputContainer">
												<div className="signupInputLabelContainer">
													<div className="signupInputLabel">Email</div>
												</div>
												<div className="signupInputLabelContainerTwo">
													<input placeholder="Email" type="email" tabIndex="1000" value={email} onChange={(event) => setEmail(event.target.value)} />
												</div>
											</div>
											<div className="signupSubmit loginSubmit" onClick={loginSubmit}>
												<div className="signupButtonLabel">Continue</div>
											</div>
										</div>
									) : (
										<div>
											<div className="loginTextContainer">Check your email</div>
											<div className="recoverCompletedContainer">
												Please go to your <strong>{email}</strong> email and click the password reset link we’ve sent for your LeagueTracker account.
											</div>
											<div className="recoverCompletedContainer">
												It could take a few minutes to appear, and be sure to check any spam ad promotional folders–just in case!
											</div>
											<div
												className="signupSubmit loginSubmit"
												onClick={() =>
													history.push({
														pathname: `/`,
													})
												}
											>
												<div className="signupButtonLabel">Home</div>
											</div>
										</div>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyBF5KLnb8yZ_PZ6_OoIxz3Tk-liecQGXf0",
	authDomain: "encryptedwallet-e3b7f.firebaseapp.com",
	projectId: "encryptedwallet-e3b7f",
	storageBucket: "encryptedwallet-e3b7f.appspot.com",
	messagingSenderId: "432294561463",
	appId: "1:432294561463:web:51c25759ecbf367067ef32",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

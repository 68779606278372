import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "./Firebase";

export default function Header({ page }) {
	return (
		<div className="masterHeader">
			<div className="masterHeaderContainer">
				<div className="logoNav">
					<Link
						className="logoContainer"
						to={{
							pathname: "/",
						}}
					>
						<div className="logo">EncryptedWallet</div>
					</Link>
				</div>
				<div className="masterHeaderAccountInfo">
					{page !== "addCard" ? (
						<div className="settingsMenu">
							<div className="menuItemContainer">
								<Link
									className="menuItem"
									to={{
										pathname: "/add-card",
									}}
								>
									<div className="menuItemLabel">Add Card</div>
								</Link>
							</div>
						</div>
					) : (
						""
					)}
					{page !== "cardList" ? (
						<div className="settingsMenu">
							<div className="menuItemContainer">
								<Link
									className="menuItem"
									to={{
										pathname: "/card-list",
									}}
								>
									<div className="menuItemLabel">Card List</div>
								</Link>
							</div>
						</div>
					) : (
						""
					)}

					<div className="settingsMenu">
						<div className="menuItemContainer">
							<div className="menuItem" onClick={() => auth.signOut()}>
								<div className="menuItemLabel">Log Out</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AddCard from "./pages/AddCard";
import CardList from "./pages/CardList";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RecoverAccount from "./pages/RecoverAccount";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import "./index.css";
import { auth } from "./components/Firebase";

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: false,
		};
	}

	componentDidMount() {
		auth.onAuthStateChanged((userAuth) => {
			this.setState({ user: userAuth });
		});
	}

	render() {
		return (
			<Router>
				{!this.state.user ? (
					<Switch>
						<Route exact path="/login">
							<Login />
						</Route>
						<Route exact path="/register">
							<Register />
						</Route>
						<Route exact path="/recover-account">
							<RecoverAccount />
						</Route>
						<Route exact path="/terms-of-use">
							<TermsOfUse />
						</Route>
						<Route exact path="/privacy-policy">
							<PrivacyPolicy />
						</Route>
						<Redirect from="/" to="/login" />
					</Switch>
				) : (
					<Switch>
						<Route exact path="/card-list">
							<CardList />
						</Route>
						<Route exact path="/add-card">
							<AddCard />
						</Route>
						<Redirect from="/" to="/add-card" />
					</Switch>
				)}
			</Router>
		);
	}
}

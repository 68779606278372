import React, { useState } from "react";
import { auth } from "../components/Firebase";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import axios from "axios";

const CryptoJS = require("crypto-js");
const api = "https://bowls2go.com/api";
const testAPI = "http://localhost:5001/encryptedwallet-e3b7f/us-central1/api/api";

export default function AddCard() {
	const [cardNumber, setCardNumber] = useState("");
	const [cvv, setCVV] = useState("");
	const [expirationMonth, setExpirationMonth] = useState("");
	const [expirationYear, setExpirationYear] = useState("");
	const [error, setError] = useState();

	const onlyNumbers = (number) => {
		return /^\d+$/.test(number);
	};

	const handleCardSubmit = () => {
		setError();

		if (onlyNumbers(cardNumber) && onlyNumbers(cvv) && onlyNumbers(expirationMonth) && onlyNumbers(expirationYear)) {
			const encryptedCardNumber = CryptoJS.AES.encrypt(JSON.stringify(cardNumber), auth.currentUser.uid).toString();
			const encryptedCVV = CryptoJS.AES.encrypt(JSON.stringify(cvv), auth.currentUser.uid).toString();
			const encryptedMonth = CryptoJS.AES.encrypt(JSON.stringify(expirationMonth), auth.currentUser.uid).toString();
			const encryptedYear = CryptoJS.AES.encrypt(JSON.stringify(expirationYear), auth.currentUser.uid).toString();

			axios
				.post(`${api}/store-encrypted-card`, {
					email: auth.currentUser.email,
					encryptedCardNumber,
					encryptedCVV,
					encryptedMonth,
					encryptedYear,
				})
				.then((response) => {
					if (!response.data.success) setError({ message: "An error occurred." });
					else setError({ message: "Encrypted card successfully stored." });
				});
		} else setError("There is an error.");
	};

	return (
		<div className="rootContainer">
			<Header page="addCard" />
			<div className="pageContentLandingPage">
				<div className="mainContent">
					<div className="contentWrapper">
						<div className="content">
							<div className="signupContainer">
								<form className="signupForm" onSubmit={handleCardSubmit}>
									<div className="loginTextContainer">Add Card</div>
									<div className="signupCustomInputContainer">
										<div className="signupInputLabelContainer">
											<div className="signupInputLabel">Card Number</div>
										</div>
										<div className="signupInputLabelContainerTwo">
											<input
												placeholder="Card Number"
												type="cardnumber"
												tabIndex="1000"
												value={cardNumber}
												onChange={(event) => setCardNumber(event.target.value)}
											/>
										</div>
									</div>
									<div className="signupCustomInputContainer">
										<div className="signupInputLabelContainer">
											<div className="signupInputLabel">CVV</div>
										</div>
										<div className="signupInputLabelContainerTwo">
											<input placeholder="CVV" type="cvv" tabIndex="1000" value={cvv} onChange={(event) => setCVV(event.target.value)} />
										</div>
									</div>
									<div className="signupCustomInputContainer">
										<div className="signupInputLabelContainer">
											<div className="signupInputLabel">Expiration Month</div>
										</div>
										<div className="signupInputLabelContainerTwo">
											<input
												placeholder="Expiration Month"
												type="expirationmonth"
												tabIndex="1000"
												value={expirationMonth}
												onChange={(event) => setExpirationMonth(event.target.value)}
											/>
										</div>
									</div>
									<div className="signupCustomInputContainer">
										<div className="signupInputLabelContainer">
											<div className="signupInputLabel">Expiration Year</div>
										</div>
										<div className="signupInputLabelContainerTwo">
											<input
												placeholder="Expiration Year"
												type="expirationyear"
												tabIndex="1000"
												value={expirationYear}
												onChange={(event) => setExpirationYear(event.target.value)}
											/>
										</div>
									</div>
									{error ? <div className="signupError">{error.message}</div> : ""}
									<div className="signupSubmit loginSubmit" onClick={handleCardSubmit}>
										<div className="signupButtonLabel">Submit</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	return (
		<div>
			<div>Add Card</div>
			<input placeholder="Card Number" type="cardnumber" value={cardNumber} onChange={(event) => setCardNumber(event.target.value)} />
			<input placeholder="CVV" type="cvv" value={cvv} onChange={(event) => setCVV(event.target.value)} />
			<input placeholder="Expiration Month" type="expirationmonth" value={expirationMonth} onChange={(event) => setExpirationMonth(event.target.value)} />
			<input placeholder="Expiration Year" type="expirationyear" value={expirationYear} onChange={(event) => setExpirationYear(event.target.value)} />
			<div onClick={() => handleCardSubmit()}>Submit</div>
			<div onClick={() => auth.signOut()}>Log Out</div>
			<Link to="./card-list">List of Credit Cards</Link>
			{error ? <div>{error}</div> : ""}
		</div>
	);
}
